import * as Yup from "yup"
import { REGEX } from "constant/globalConstants"
import { useState } from "react"
import {
    AES256_GCM_decrypt,
    AES256_GCM_ENCRYPT
} from "constant/EncryptionDecryptionUtils"
import { registerLightInvestor } from "services"
import { newPasswordApi } from "services"
import { useSnackbar } from "components/common/snackbar/SnackbarProvider"
import { useNavigate } from "react-router-dom"
const defaultValues = {
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: ""
}
const passwordPatternMsg =
    "Password Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character (^ $ * . [ ] { } ( ) ? \"! @ # % & / \\ , > < ' : ; | _ ~ ` = + -)"

const validationSchema = Yup.object({
    firstName: Yup.string().required("First name required"),
    lastName: Yup.string().required("Last name required"),
    email: Yup.string()
        .email("Valid email required")
        .required("Email required"),
    password: Yup.string()
        .trim()
        .required("Password required")
        .matches(REGEX.password, passwordPatternMsg),

    confirmPassword: Yup.string()
        .trim()
        .required("Confirm password required")
        .matches(REGEX.password, passwordPatternMsg)
        .oneOf([Yup.ref("password"), null], "Passwords must match")
})

const fields = [
    {
        name: "email",
        label: "Email",
        placeholder: "Enter email address",
        type: "email"
    },
    {
        name: "password",
        label: "Password",
        placeholder: "Enter password",
        type: "password"
    },
    {
        name: "confirmPassword",
        label: "Confirm password",
        placeholder: "Enter confirm password",
        type: "password"
    }
]

export const useLightInvestorReg = () => {
    const [loaderText, setLoaderText] = useState(
        "Creating your account... Please wait."
    )
    const [isLoading, setIsLoading] = useState(false)
    const { onError, onSuccess } = useSnackbar()
    const navigate = useNavigate()

    // ** submit
    const handleSubmit = async (data) => {
        setIsLoading(true)
        const registerPayload = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email
        }
        const plainPassword = data.password

        // ** step1: register
        registerLightInvestor(registerPayload)
            .then(async (resp) => {
                // ** If success
                if (resp?.data?.status) {
                    // ** step2: if success prepare create password payload
                    const encryptedPassword =
                        await AES256_GCM_ENCRYPT(plainPassword)
                    const createPasswordPayload = {
                        encryptedTxt: resp.data.body?.passwordResetToken || "",
                        password: encryptedPassword
                    }

                    // ** hit api
                    setLoaderText("Setting up your password... Almost done!")
                    newPasswordApi(createPasswordPayload).then(async (res) => {
                        if (res?.data?.status) {
                            const respData = res?.data?.body
                            onSuccess(res.data.statusMessage)
                            const plainEmail = await AES256_GCM_decrypt({
                                data: { encryptedValue: respData.email }
                            })
                            navigate("/verify", {
                                state: {
                                    ...respData,
                                    email: plainEmail,
                                    mobile: "",
                                    password: plainPassword
                                }
                            })
                        } else {
                            onError(res?.data?.errorMessage)
                        }
                    })
                } else {
                    onError(resp?.data?.errorMessage)
                }
            })
            .catch()
            .finally(() => {
                setIsLoading(false)
            })
    }

    return {
        handleSubmit,
        defaultValues,
        validationSchema,
        fields,
        isLoading,
        loaderText
    }
}
